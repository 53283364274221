import React, { useState, useRef } from 'react';
import './App.css';
import anim from './animation/MiTu_GIF.gif';

const App = () => {
  const [formType, setFormType] = useState('fastboot');
  const [searchMethodVisible, setSearchMethodVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fastbootData, setFastbootData] = useState(null);
  const [otaData, setOtaData] = useState(null);
  const [showDonate, setShowDonate] = useState(false);

  const codeNameRef = useRef(null);
  const deviceRegionRef = useRef(null);
  const isOldRef = useRef(null);
  const versionRef = useRef(null);
  const deviceRef = useRef(null);

  function toggleForm(type) {
    setFormType(type);
    if (type === 'fastboot') {
      setOtaData(null);
    } else {
      setFastbootData(null);
    }
  }

  function handleDeviceRegionChange() {
    const value = deviceRegionRef.current ? deviceRegionRef.current.value : '';
    setSearchMethodVisible(value === '_id_global' || value === '_ru_global');
  }

  function fastbootROM(event) {
    event.preventDefault();
    setLoading(true);
    setFastbootData(null);

    const device = codeNameRef.current ? codeNameRef.current.value.trim().toLowerCase() : '';
    const region = deviceRegionRef.current ? deviceRegionRef.current.value : '';
    const isOld = isOldRef.current ? isOldRef.current.value : '';

    let index;
    switch (region) {
      case "_global":
        index = "global";
        break;
      case "_ru_global":
        index = isOld === "0" ? "global" : "ru";
        break;
      case "_eea_global":
        index = "eea";
        break;
      case "_tw_global":
        index = "tw";
        break;
      case "_id_global":
        index = isOld === "0" ? "global" : "id";
        break;
      case "_in_global":
        index = "in";
        break;
      default:
        index = "cn";
        break;
    }

    const formData = {
      d: device + region,
      b: "F",
      r: index,
      l: "en-en"
    };

    fetch('https://API.catflasher.online/generateMIUI_FASTBOOT.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          console.error(data.error);
          setFastbootData({ error: data.error });
        } else {
          setFastbootData(data);
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setFastbootData({ error: 'Response error: ' + error });
      })
      .finally(() => setLoading(false));
  }

  function miuiOTAsearch(event) {
    event.preventDefault();
    setOtaData(null);

    const version = versionRef.current ? versionRef.current.value.trim().toUpperCase() : '';
    const device = deviceRef.current ? deviceRef.current.value.trim().toLowerCase() : '';

    const formData = new URLSearchParams();
    formData.append('v', version);
    formData.append('d', device);

    fetch('https://API.catflasher.online/generateMIUI_ROM.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          console.error(data.error);
          setOtaData({ error: data.error });
        } else {
          setOtaData(data);
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setOtaData({ error: 'Response error: ' + error });
      });
  }

  const renderDonateSection = () => (
    <div className="container" id="container">
      <h1>Support Project</h1>
      <p>Your help is so important to us! It allows us to keep developing the project and keep it running. We are so grateful for any help you can give us!</p>
      <div className="donate-methods">
        <p>We'd love your support in any of the following ways:</p>
        <ul>
          <li><a href="https://www.paypal.me/" target="_blank" rel="noopener noreferrer" className="donate-link">PayPal(later)</a></li>
        </ul>
      </div>
      <div className="back-to-home">
        <button onClick={() => setShowDonate(false)} className="donate-link">Back to main page</button>
      </div>
    </div>
  );

  const renderFastbootData = () => {
    if (!fastbootData) return null;

    if (fastbootData.error) {
      return <div style={{ color: 'red' }}>{fastbootData.error}</div>;
    }
    const version = fastbootData.version;
    const filename = fastbootData.filename;

    return (
      <div>
        <h1>{fastbootData.device}</h1>
        <h2>{fastbootData.version}</h2>
        <h3>Android Version: {fastbootData.android_version}</h3>
        <h3>File Name: {fastbootData.filename}</h3>
        <h3>File Size: {fastbootData.filesize}</h3>
        <h3>MD5: {fastbootData.md5}</h3>
        <h3>Description: {fastbootData.description}</h3>
        <h3>Download Mirrors:</h3>
        <div>
          {Object.entries(fastbootData.mirrors).map(([key, url]) => (
            <div key={key}>
              <button
                onClick={() => window.open(`${url}/${version}/${filename}`, '_blank')}
                style={{ margin: '5px 0' }}
              >{url}</button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderOtaData = () => {
    if (!otaData) return null;

    if (otaData.error) {
      return <div style={{ color: 'red' }}>{otaData.error}</div>;
    }

    return (
      <div>
        <h1>{otaData.device}</h1>
        <h2>{otaData.version}</h2>
        <h3>Android Version: {otaData.android_version}</h3>
        <h3>File Size: {otaData.filesize}</h3>
        <h3>MD5: {otaData.md5}</h3>
        <h3>Description: {otaData.description}</h3>
        <button onClick={() => window.open(otaData.download_url, '_blank')}>
          Download
        </button>
      </div>
    );
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <nav className="header-nav">
          <a href="/#" onClick={() => setShowDonate(false)}>Main</a>
          <a href="/#" onClick={() => setShowDonate(true)}>Donate</a>
          <a href="/#" className="inactive-link">API (Not available yet)</a>
        </nav>
      </header>
      {showDonate ? (
        renderDonateSection()
      ) : (
        <div className="container" id="container">
          <h1>Xiaomi Firmware Search</h1>
          <div className="toggle-button">
            <button
              id="btnFastboot"
              className={formType === 'fastboot' ? 'active' : ''}
              onClick={() => toggleForm('fastboot')}
            >
              Fastboot
            </button>
            <button
              id="btnOTA"
              className={formType === 'ota' ? 'active' : ''}
              onClick={() => toggleForm('ota')}
            >
              OTA
            </button>
          </div>
          <div id="fastbootForm" className={`animate__animated ${formType === 'fastboot' ? '' : 'hidden'}`}>
            <form onSubmit={fastbootROM}>
              <p>Enter the codename of the device:</p>
              <input type="text" ref={codeNameRef} required />

              <p>Select the desired ROM region:</p>
              <select ref={deviceRegionRef} onChange={handleDeviceRegionChange} required>
                <option value="_global">Global</option>
                <option value="_eea_global">Europe Global</option>
                <option value="_ru_global">Russian Global</option>
                <option value="_tw_global">Taiwan Global</option>
                <option value="_id_global">Indonesian Global</option>
                <option value="_in_global">Indian Global</option>
                <option value="">Chinese</option>
              </select>

              {searchMethodVisible && (
                <div id="search-method">
                  <p>Select a search method to use:</p>
                  <select ref={isOldRef}>
                    <option value="1">HyperOS</option>
                    <option value="0">MIUI</option>
                  </select>
                </div>
              )}

              <input id="submit-button" type="submit" value="Submit" />
            </form>
            <div id="container_info" className={`container`}>
              {loading && <img id="loadingGif" src={anim} alt="Loading" />}
              {renderFastbootData()}
            </div>
          </div>
          <div id="otaForm" className={`animate__animated ${formType === 'ota' ? '' : 'hidden'}`}>
            <form onSubmit={miuiOTAsearch}>
              <p>Enter ROM version:</p>
              <input
                type="text"
                ref={versionRef}
                placeholder="V14.0.13.0.TLCMIXM | OS1.0.2.0.ULCMIXM"
                required
              />
              <p>Enter the codename of the device:</p>
              <input type="text" ref={deviceRef} placeholder="cupid" required />

              <input id="submit-button-ota" type="submit" value="Submit" />
            </form>
            <div id="container_info-ota" className={`container`}>
              {renderOtaData()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
